import { WindowLocation } from "@reach/router"
import { graphql, Link, useStaticQuery } from "gatsby"
import { default as React } from "react"
import Layout from "../components/layout/layout"
import { slugify } from "../utility/urlBuilder"
export default function OverOns({ location }: { location: WindowLocation }) {
  const { allProductsJson } = useStaticQuery(
    graphql`
      query {
        allProductsJson(filter: { hoofdcategorie: { ne: "" } }) {
          edges {
            node {
              hoofdcategorie
              subcategorienaam
            }
          }
        }
      }
    `
  )

  return (
    <Layout title="Over ons" location={location}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "3rem",
          backgroundColor: "rgba(255, 255, 255, 0.73)",
          borderRadius: "1.375em",
          fontSize: "1.125em",
          padding: "1.5rem",
          flexWrap: "wrap",
        }}
        className="page"
      >
        <div style={{ flex: "2", minWidth: "var(--minWidth)" }}>
          <h2>Populaire producten</h2>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              whiteSpace: "nowrap",
              justifyContent: "space-between",
            }}
          >
            {allProductsJson.edges
              .map((a: any) =>
                a.node.subcategorienaam?.length > 0
                  ? a.node.subcategorienaam
                  : a.node.hoofdcategorie
              )
              .filter((x: any, i: any, a: any) => a.indexOf(x) == i)
              .sort()
              .map((value: any, i: number) => (
                <React.Fragment key={i + "footer"}>
                  <Link
                    to={"/" + slugify(value)}
                    style={{
                      minWidth: "250px",
                      overflow: "hidden",
                      flex: "1",
                    }}
                  >
                    {value}
                  </Link>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
